import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuDrawer from "./MenuDrawer";
import RedirectTwitch from "./RedirectTwitch";
import LinkTwitch from "./LinkTwitch";
import Logout from "./Logout";
import Manage from "./Manage";
import Register from "./Register";
import LinkDevice from "./LinkDevice";
import DisplayDevices from "./DisplayDevices";

class MenuBar extends Component {

	constructor(props) {
		super(props);

		this.state = {
			currentPage: "Error",
			auth: true,
			anchorEl: false,
			drawerIsOpen: false,
			open: false,
		}
	}

	setTitle = (currentPage) => {
		this.setState({ currentPage: currentPage });
		document.title = currentPage;
	}

	handleToggleDrawer = booleanValue => () => {
		this.setState({ drawerIsOpen: booleanValue });
	}

	render() {
		return (
			<div>
				<BrowserRouter>
					<AppBar position="static">
						<Toolbar>
							<IconButton
								edge="start"
								color="inherit"
								onClick={this.handleToggleDrawer(true)}
							>
								<MenuIcon />
							</IconButton>

							<Typography variant="h6" style={{ marginLeft: '15px', marginRight: '15px' }}>
								{this.state.currentPage}
							</Typography>

							{this.state.auth && (
								<div>
									<IconButton
										aria-label="account of current user"
										aria-controls="menu-appbar"
										aria-haspopup="true"
										color="inherit"
									>
										<AccountCircle />
									</IconButton>
								</div>
							)}
						</Toolbar>
					</AppBar>

					<MenuDrawer drawerIsOpen={this.state.drawerIsOpen} toggleDrawer={this.handleToggleDrawer} auth={this.state.auth} />

					<Switch>
						<Route exact path="/login">
							<Redirect to="/manage"/>
						</Route>
						<Route exact path="/linktwitch">
							<RedirectTwitch setTitle={this.setTitle}/>
						</Route>
						<Route exact path="/linkdevice">
							<LinkDevice setTitle={this.setTitle}/>
						</Route>
						<Route exact path="/display">
							<DisplayDevices setTitle={this.setTitle}/>
						</Route>
						<Route exact path="/auth">
							<LinkTwitch setTitle={this.setTitle}/>
						</Route>
						<Route exact path="/logout">
							<Logout setTitle={this.setTitle}/>
						</Route>
						<Route exact path="/manage">
							<Manage setTitle={this.setTitle}/>
						</Route>
						<Route exact path="/register">
							<Register />
						</Route>
						<Route path="/">
							<Redirect to="/manage"/>
						</Route>
					</Switch>
				</BrowserRouter>
			</div>
		);
	}
}

export default MenuBar;
import React, {Component} from "react";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

class LinkTwitch extends Component {

  constructor(props) {
    super(props);

    this.state = {
      codeValue: '',
      twitchUserLogo: '',
      plainToken: '',
      OAuthToken: '',
      buttonText: 'Link Account',
      isLoading: false,
      success: false,
      error: false,
      displayName: '',
      channelId: '',
    };

  }

  componentDidMount() {
    this.props.setTitle("Link Twitch");

    const plainToken = queryString.parse(this.props.location.hash).access_token;
    const OAuthToken = "OAuth " + plainToken;
    this.setState({ OAuthToken: OAuthToken });
    this.setState({ plainToken: plainToken });

    const requestOptions = {
        method: 'GET',
        headers: {'Accept':'application/vnd.twitchtv.v5+json', 'Client-ID':'b5srvsw88ej7qzbo7ncy0kz7cmyh57', 'Authorization':OAuthToken}
    };

    fetch('https://api.twitch.tv/kraken/channel', requestOptions)
    .then(response => response.json())
    .then(data => {
      console.log(data);
      this.setState({ twitchUserEmail: data.email });
      this.setState({ twitchUserLogo: data.logo });
      this.setState({ displayName: data.display_name });
      this.setState({ channelId: data._id });
    })
  }

  handleSubmit = () => {
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
    };

    this.setState({isLoading : true})

    fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/apiKey/twitch/add?apiKey=' + this.state.plainToken + '&channelId=' + this.state.channelId + '&twitchUsername=' + this.state.displayName, requestOptions)
      .then(() => {
        setTimeout(() => {
        this.setState({isLoading : false});
        this.setState({success : true});
        this.setState({buttonText : 'Link Successful'});
        }, 1000);
    }).catch(() => {
        this.setState({isLoading : false});
        this.setState({error : true});
    });
  }

  render() {
    console.log("SubmitCode.js")
    return (
      <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
      }}>
        <Form>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px'
          }}>
            <img src={this.state.twitchUserLogo} alt="Logo" height="100" width="100"/>
          </div>

          <center>
            <Button disabled={this.state.isLoading || this.state.success} variant="primary" type="link" onClick={!this.state.isLoading ? this.handleSubmit : null}>
            {this.state.buttonText}  {this.state.isLoading && <Spinner animation="border" size="sm" role="status" aria-hidden="true"/>}
            </Button>
          </center>
        </Form>
      </div>
    );
  }

}

export default withRouter(LinkTwitch);
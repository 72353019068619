import React, {Component} from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';

class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      loginSuccessful: false,
      errors: {},
    };

  }

  componentDidMount(){
    document.title = "Login";
  }

  attemptLogin = () => {
    this.setState({errors : {}});

    var headers = new Headers();
    headers.append('Authorization', 'Basic ' + Buffer.from(this.state.username + ":" + this.state.password).toString('base64'));
    const requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
    };

    const endpoint = process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/login';

    fetch(endpoint, requestOptions)
      .then((response) => {
        if(response.ok){
          this.setState({loginSuccessful : true})
          window.location.reload(false);
        }
        else{
          if(response.status === 401){
            console.log(response);
            this.setState({errors : "Invalid Credentials"});
          }
          this.setState({loginSuccessful : false})
        }
    });

  }

  handleUsernameChange = (event) => {
    this.setState({username : event.target.value});
  }

  handlePasswordChange = (event) => {
    this.setState({password : event.target.value});
  }

  handleKeyPress = (target) => {
    // Code 13 is the 'Enter' key
    if (target.charCode === 13) {
      this.attemptLogin();
    }
  }

  render() {
    return (
      <div style={{
          position: 'absolute',
          left: '50%',
          top: '25%',
          transform: 'translate(-50%, -25%)'
      }}>

        <Form className="bg-light" fluid="sm" style={{
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 20,
          paddingRight: 20,
          borderRadius: 10,
        }}>
          <Form.Group as={Row} controlId="loginLabel">
            <Form.Label column sm="3"></Form.Label>
            <Col sm="8">
              <center>
                <h3>Login</h3>
              </center>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formUsername">
            <Form.Label column sm="3">Username</Form.Label>
            <Col sm="8">
              <Form.Control type="text" placeholder="Username" onChange={this.handleUsernameChange} onKeyPress={this.handleKeyPress}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formPassword">
            <Form.Label column sm="3">Password</Form.Label>
            <Col sm="8">
              <Form.Control type="password" placeholder="Password" onChange={this.handlePasswordChange} onKeyPress={this.handleKeyPress}/>
              <Form.Text className="text-muted">
              Dont have an account? <a href="/register">Register</a>
              </Form.Text>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formButton">
            <Form.Label column sm="3"></Form.Label>
            <Col sm="8">
              <Button className='btn-block' onClick={this.attemptLogin}>Login</Button>
            </Col>
          </Form.Group>

        </Form>

        {this.state.errors.length && <Alert variant="danger">
          {this.state.errors}
        </Alert>}
      </div>
    );
  }

}

export default Login;
import React, {Component} from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class Register extends Component {

  constructor(props) {
    super(props);

    this.state = {
      username : '',
      usernameAvailable : true,
      isUsernameValid: false,
      isUsernameInvalid: false,
      usernameErrors : [''],
      password : '',
      isPasswordValid: false,
      isPasswordInvalid: false,
      passwordErrors : [''],
      email : '',
      isEmailValid: false,
      isEmailInvalid: false,
      emailErrors : [''],
      totalFormInvalid : true,
    };

  }

  componentDidMount(){
    document.title = "Register";
  }

  validateEmail = (email) => {
    var isEmailInvalid = false;
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var emailErrors = [];

    if(!re.test(email)){
      isEmailInvalid = true;
      emailErrors.push('Email is invalid.');
    }

    this.setState({email : email});
    this.setState({isEmailInvalid : isEmailInvalid});
    this.setState({isEmailValid : !isEmailInvalid});
    this.setState({emailErrors : emailErrors}, () => {
      this.validateTotalForm();
    });

    return isEmailInvalid;
  }

  validateUsername = (username) => {
    var isWrongLength = false;
    var isUsernameUnavailable = false;

    var usernameErrors = [];

    if(username.length < 6 || username.length > 24){
      isWrongLength = true;
      usernameErrors.push('Username length must be between 6 and 24 characters.');
    }

    if(!this.state.usernameAvailable){
      isUsernameUnavailable = true;
      usernameErrors.push('\nUsername is unavailable.');
    }

    var isInvalid = (isWrongLength || isUsernameUnavailable);

    this.setState({isUsernameInvalid : isInvalid});
    this.setState({isUsernameValid : !isInvalid});
    this.setState({usernameErrors : usernameErrors}, () => {
      this.validateTotalForm();
    });

    return isInvalid;
  }

  validatePassword = (password) => {
    var isPasswordInvalid = false;
    var passwordErrors = [];

    if(password.length < 8 || password.length > 64){
      isPasswordInvalid = true;
      passwordErrors.push('\nPassword must be between 8 and 64 characters.');
    }

    this.setState({password : password});
    this.setState({isPasswordInvalid : isPasswordInvalid});
    this.setState({isPasswordValid : !isPasswordInvalid});
    this.setState({passwordErrors : passwordErrors}, () => {
      this.validateTotalForm();
    });

    return isPasswordInvalid;
  }

  validateTotalForm = () => {
    var totalFormInvalid = this.state.emailErrors.length > 0 || this.state.usernameErrors.length > 0 || this.state.passwordErrors.length > 0;
    this.setState({totalFormInvalid : totalFormInvalid});
  }

  handleEmailChange = (event) => {
    const email = event.target.value;
    this.validateEmail(email);
  }

  handleUsernameChange = (event) => {
    this.setState({username: event.target.value})

    const requestOptions = {
      method: 'GET'
    };

    const username = event.target.value;
    const endpoint = process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/register/checkavailability/' + username;

    fetch(endpoint, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.setState({usernameAvailable : data});
        this.validateUsername(username);
    });
  }

  handlePasswordChange = (event) => {
    const password = event.target.value;
    this.validatePassword(password);
  }

  registerUser = () => {
    console.log('Trying to register user.');
    const requestOptions = {
      method: 'POST'
    };
    const endpoint = process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/register/?username=' + this.state.username + "&email=" + this.state.email + '&password=' + this.state.password;

    fetch(endpoint, requestOptions).then(() => {
      window.location.pathname = '/'
    })
  }

  render() {
    return (
      <div style={{
          position: 'absolute',
          left: '50%',
          top: '25%',
          transform: 'translate(-50%, -25%)'
      }}>

        <Form className="bg-light" fluid="sm" style={{
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 20,
          paddingRight: 20,
          borderRadius: 10,
        }}>
          <Form.Group as={Row} controlId="registerLabel">
            <Form.Label column sm="3"></Form.Label>
            <Col sm="8">
              <center>
                <h3>Register</h3>
              </center>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formEmail">
            <Form.Label column sm="3">Email</Form.Label>
            <Col sm="8">
              <Form.Control type="email" placeholder="Email" onChange={this.handleEmailChange} isInvalid={this.state.isEmailInvalid} isValid={this.state.isEmailValid}/>
              <Form.Control.Feedback type="invalid">
                {this.state.emailErrors}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formUsername">
            <Form.Label column sm="3">Username</Form.Label>
            <Col sm="8">
              <Form.Control type="text" placeholder="Username" onChange={this.handleUsernameChange} isInvalid={this.state.isUsernameInvalid} isValid={this.state.isUsernameValid}/>
              <Form.Control.Feedback type="invalid">
                {this.state.usernameErrors}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formPassword">
            <Form.Label column sm="3">Password</Form.Label>
            <Col sm="8">
              <Form.Control type="password" placeholder="Password" onChange={this.handlePasswordChange} isInvalid={this.state.isPasswordInvalid} isValid={this.state.isPasswordValid}/>
              <Form.Control.Feedback type="invalid">
                {this.state.passwordErrors}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formButton">
            <Form.Label column sm="3"></Form.Label>
            <Col sm="8">
              <Button className='btn-block' disabled={this.state.totalFormInvalid} onClick={this.registerUser}>Register</Button>
            </Col>
          </Form.Group>

        </Form>
      </div>
    );
  }

}

export default Register;
import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

class EditDeviceModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isShowing: false,
      contentGenerators: {},
      selectedContentGeneratorId: {},
      displayName: '',
    };
  }

  getContentGenerators = () => {
    const requestOptions = {
      method: 'GET',
      credentials: 'include',
    };

    fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/contentGenerator', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ contentGenerators: data });
      });
  }

  editDevice = (deviceId, contentGeneratorId, displayName) => {
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
    };

    fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/device/edit?deviceId=' + deviceId + '&contentGeneratorId=' + contentGeneratorId + '&displayName=' + displayName, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.props.onClose(data);
      });
  }

  handleShow = () => {
    this.setState({ isShowing: true });
    this.setState({ displayName: this.props.device.displayName})
    this.getContentGenerators();
  }

  handleClose = () => {
    this.setState({ isShowing: false });
  }

  handleContentGeneratorSelectionChange = (event) => {
    this.setState({selectedContentGeneratorId : event.target.value})
  }

  handleDisplayNameChange = (event) => {
    this.setState({displayName : event.target.value})
  }

  handleSave = () => {
    this.editDevice(this.props.device.deviceId, this.state.selectedContentGeneratorId, this.state.displayName);
    this.handleClose();
  }

  render() {
    return (
      <>
        <Modal show={this.state.isShowing} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Device</Modal.Title>
          </Modal.Header>
          <Form style={{marginTop: '10px' , marginLeft: '10px' , marginRight: '10px'}}>

            <Form.Group controlId="controlSelectContentGenerator">
              <Form.Label>Select a Content Generator</Form.Label>
              <Form.Control as="select" onChange={this.handleContentGeneratorSelectionChange}>
                <option>Select a Content Generator</option>
                {Array.from(this.state.contentGenerators).map((contentGenerator, index) => {
                  if(this.props.device.assignedContentGenerator !== null && contentGenerator.id === this.props.device.assignedContentGenerator.id){
                    return <option selected key={index} value={contentGenerator.id}>{contentGenerator.displayName}</option>
                  }
                  return <option key={index} value={contentGenerator.id}>{contentGenerator.displayName}</option>
                })}
              </Form.Control>
              <Form.Text className="text-muted">
                The Content Generator assigned to the device.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="controlDisplayName">
              <Form.Label>Device Name</Form.Label>
              <Form.Control type="Username" placeholder={this.props.device.displayName} onChange={this.handleDisplayNameChange}/>
              <Form.Text className="text-muted">
                The device name.
              </Form.Text>
            </Form.Group>

          </Form>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.handleSave}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

}

export default EditDeviceModal;
import React, {Component} from "react";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';

class LinkDevice extends Component {

  constructor(props) {
    super(props);

    this.state = {
      devices: {},
      deviceName: '',
      shortCode: {},
      isLoading: false,
      buttonText: 'Link Device',
    };

  }

  componentDidMount() {
    this.props.setTitle("Link Device");
    this.getDevices();
  }

  getDevices = () => {
    // GET request using fetch with async/await
    const requestOptions = {
      method: 'GET',
      credentials: 'include',
    };

    fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/device', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ devices: data });
        this.setState({ deviceName: "New Device " + (data.length+1)});
      });
  }

  handleSubmit = () => {
    // GET request using fetch with async/await
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
    };

    this.setState({ isLoading: true })

    fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/device/add?shortCode=' + this.state.shortCode + "&displayName=" + this.state.deviceName, requestOptions)
      .then((response) => {
        setTimeout(() => {
          this.setState({ isLoading: false });
          this.setState({ buttonText: 'Link Successful' });
          this.setState({ devices: response.data });
        }, 1000);
      }).catch(() => {
        this.setState({ isLoading: false });
      });
  }

  handleShortCodeChange = (event) => {
    this.setState({shortCode : event.target.value});
  }

  handleDisplayNameChange = (event) => {
    this.setState({displayName : event.target.value});
  }

  render() {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px'
      }}>
        <Form>

          <Form.Group controlId="formShortCode">
            <Form.Label>Code</Form.Label>
            <Form.Control type="text" placeholder="Code" onChange={this.handleShortCodeChange} />
          </Form.Group>

          <Form.Group controlId="controlDisplayName">
            <Form.Label>Device Name</Form.Label>
            <Form.Control type="Username" placeholder={this.state.deviceName} onChange={this.handleDisplayNameChange}/>
            <Form.Text className="text-muted">
              Name the new device.
            </Form.Text>
          </Form.Group>

          <center>
            <Button disabled={this.state.isLoading || this.state.success} variant="primary" type="link" onClick={!this.state.isLoading ? this.handleSubmit : null}>
            {this.state.buttonText}  {this.state.isLoading && <Spinner animation="border" size="sm" role="status" aria-hidden="true"/>}
            </Button>
          </center>

        </Form>
      </div>
    );
  }

}

export default LinkDevice;
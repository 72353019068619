import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import EditDeviceModal from "./EditDeviceModal";
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';

class DeviceRow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isHidden: true,
    };

    this.modalElement = React.createRef();
  }

  isHidden = (hide) => {
    this.setState({ isHidden: hide });
  }

  openModal = () =>{
    this.modalElement.current.handleShow();
  }

  render() {
    return (
      <tr>
        <td>{this.props.device.displayName}</td>
        {this.props.device.assignedContentGenerator ? <td>{this.props.device.assignedContentGenerator.displayName}</td> : <td>N/A</td>}
        {this.state.isHidden ? <td>- - - - - - - - - - - - - - - - - - - - - - - - - - - -</td> : <td>{this.props.device.deviceId}</td>}
        <td>
          <Button style={{ margin: '5px' }} variant="danger" size='sm' onClick={() => this.props.onDelete()}><DeleteForeverRoundedIcon/></Button>
          {this.state.isHidden ? <Button style={{ margin: '5px' }} variant="primary" size='sm' onClick={() => this.isHidden(false)}><VisibilityRoundedIcon/></Button> : <Button style={{ margin: '5px' }} variant="primary" size='sm' onClick={() => this.isHidden(true)}><VisibilityOffRoundedIcon/></Button>}
          <Button style={{ margin: '5px' }} variant="warning" onClick={this.openModal} size="sm"><EditRoundedIcon/></Button>
        </td>
        <EditDeviceModal ref={this.modalElement} device={this.props.device} onClose={this.props.onClose}/>
      </tr>
    );
  }

}

export default DeviceRow;
import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

class NewContentGeneratorModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isShowing: false,
      isSubsciberTypeSelected: false,
      isViewerTypeSelected: false,
      apiKeys: {},
      contentGenerators: {},
      selectedApiKey: {},
      username: '',
      contentGeneratorName: '',
    };
  }

  getApiKeys = () => {
    const requestOptions = {
      method: 'GET',
      credentials: 'include',
    };

    fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/apiKey', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // Store data in state
        this.setState({ apiKeys: data });
      });
  }

  getContentGenerators = () => {
    const requestOptions = {
      method: 'GET',
      credentials: 'include',
    };

    fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/contentGenerator', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // Store data in state
        this.setState({ contentGenerators: data });
        this.setState({ contentGeneratorName: "ContentGen " + (data.length+1)});
      });
  }

  createSubscriberTypeContentGenerator = (apiKey, username, channelId, contentGeneratorName) => {
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
    };

    fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/contentGenerator/add/twitchSubscriberCount?apiKey=' + apiKey + '&twitchUsername=' + username + '&channelId=' + channelId + '&displayName=' + contentGeneratorName, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.props.onClose(data);
      });
  }

  createViewerTypeContentGenerator = (apiKey, username, contentGeneratorName) => {
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
    };

    fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/contentGenerator/add/twitchViewerCount?apiKey=' + apiKey + '&twitchUsername=' + username + '&displayName=' + contentGeneratorName, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.props.onClose(data);
      });
  }

  handleShow = () => {
    this.setState({ isShowing: true });
    this.getContentGenerators();
    this.getApiKeys();
  }

  handleClose = () => {
    this.setState({ isShowing: false });
    this.clearSelections();
  }

  clearSelections = () => {
    this.setState({
      isSubsciberTypeSelected: false,
      isViewerTypeSelected: false,
    })
  }

  typeSelectionChange = (event) => {
    this.clearSelections();
    console.log(event.target.value);
    switch (event.target.value) {
      case 'subscriberCount':
        this.setState({isSubsciberTypeSelected: true});
        break;
      case 'viewerCount':
        this.setState({isViewerTypeSelected: true});
        break;
      default:
    }
  }

  handleApiKeySelectionChange = (event) => {
    this.setState({selectedApiKey : this.state.apiKeys[event.target.value]})
  }

  handleUsernameChange = (event) => {
    this.setState({username : event.target.value})
  }

  handlecontentGeneratorNameChange = (event) => {
    this.setState({contentGeneratorName : event.target.value})
  }

  handleCreate = () => {
    if(this.state.isSubsciberTypeSelected){
      this.createSubscriberTypeContentGenerator(this.state.selectedApiKey.key, this.state.selectedApiKey.apiUsername, this.state.selectedApiKey.channelId, this.state.contentGeneratorName);
    }
    if(this.state.isViewerTypeSelected){
      this.createViewerTypeContentGenerator(this.state.selectedApiKey.key, this.state.username, this.state.contentGeneratorName);
    }
    this.handleClose();
  }

  render() {
    return (
      <>
        <Modal show={this.state.isShowing} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>New Content Generator</Modal.Title>
          </Modal.Header>
          <Form style={{marginTop: '10px' , marginLeft: '10px' , marginRight: '10px'}}>

            <Form.Group controlId="controlcontentGeneratorName">
              <Form.Label>Content Generator Name</Form.Label>
              <Form.Control type="text" placeholder={this.state.contentGeneratorName} onChange={this.handlecontentGeneratorNameChange}/>
            </Form.Group>

            <Form.Group controlId="controlSelectGeneratorType">
              <Form.Label>Select Type</Form.Label>
              <Form.Control as="select" onChange={this.typeSelectionChange}>
                <option value="none">Select a Type</option>
                <option value="subscriberCount">Subscriber Count</option>
                <option value="viewerCount">Viewer Count</option>
              </Form.Control>
            </Form.Group>

            {(this.state.isSubsciberTypeSelected || this.state.isViewerTypeSelected) &&
              <Form.Group controlId="controlSelectKey">
                <Form.Label>Select API Key</Form.Label>
                <Form.Control as="select" onChange={this.handleApiKeySelectionChange}>
                  <option>Select an Key</option>
                  {Array.from(this.state.apiKeys).map((apiKey, index) => (
                    <option key={index} value={index}>{apiKey.service} - {apiKey.apiUsername}</option>
                  ))}
                </Form.Control>
                <Form.Text className="text-muted">
                  The API Key used to fetch metrics.
                </Form.Text>
              </Form.Group>
            }

            {this.state.isViewerTypeSelected &&
              <Form.Group controlId="controlUsername">
                <Form.Label>Twitch Username</Form.Label>
                <Form.Control type="Username" placeholder="Enter Username" onChange={this.handleUsernameChange}/>
                <Form.Text className="text-muted">
                  The broadcasters username to get the viewer count of.
                </Form.Text>
              </Form.Group>
            }

          </Form>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleCreate}>
              Create
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

}

export default NewContentGeneratorModal;
import React, {Component} from "react";
import DeviceCard from './DeviceCard';
import CardDeck from 'react-bootstrap/CardDeck';

class DisplayDevices extends Component {

  constructor(props) {
    super(props);

    this.state = {
      devices: {},
    };

  }

  componentDidMount() {
    this.props.setTitle("Devices");
    this.getDevices();
  }

  getDevices = () => {
    const requestOptions = {
      method: 'GET',
      credentials: 'include',
    };

    fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/device', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ devices: data });
      });
  }

  render() {
    console.log("DisplayDevices.js")
    return (
      <div style={{
        marginLeft: '15px',
        marginRight: '15px',
      }}>
        <CardDeck style={{justifyContent: 'center', minWidth: '500px', display: 'flex', flexDirection: 'row'}}>
          {Array.from(this.state.devices).sort((a, b) => a.id - b.id).map((device, index) => (
            <DeviceCard key={index} device={device}/>
          ))}
        </CardDeck>
      </div>
    );
  }

}

export default DisplayDevices;
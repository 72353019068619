import React, { Component } from "react";
import Spinner from 'react-bootstrap/Spinner'
import MenuBar from "./components/MenuBar";
import LoggedOutApp from "./components/LoggedOutApp";

class App extends Component {
  constructor() {
    super();

    this.state = {
      isLoading : true,
      isLoggedIn: false,
      user: {},
    }
  }

  componentDidMount() {
    this.checkLoggedIn();
  }

  checkLoggedIn = () => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include',
    };

    fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/isAuthenticated', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if(data){
          this.setState({isLoggedIn : true});
        }
        else{
          this.setState({isLoggedIn : false});
        }
        this.setState({isLoading : false});
      })
      .catch((error) => {
        console.log(error)
        this.checkLoggedIn();
      })
  }

  render() {
    if(this.state.isLoading){
      return (
        <center>
          <Spinner animation="grow" size="lg" variant="primary" />
        </center>
      )
    }
    return (
      <div>
        {this.state.isLoggedIn ? <MenuBar/> : <LoggedOutApp />}
      </div>
    );
  }

}

export default App;
import React, {Component} from "react";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

class RedirectTwitch extends Component {

	constructor(props) {
    super(props);

    this.content = '2;URL=https://id.twitch.tv/oauth2/authorize?client_id=b5srvsw88ej7qzbo7ncy0kz7cmyh57&redirect_uri=' + process.env.REACT_APP_STREAMAPPS_FRONTEND_AUTH_URL + '&response_type=token&scope=channel_read channel_subscriptions';
  }

	componentDidMount() {
		this.props.setTitle("Link Twitch");
	}

  render() {
		console.log("LinkTwitch.js")
    return (
			<div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
      }}>
				<Box p={10}>
					<Typography gutterBottom variant="h5" component="h2">
						Redirecting to Twitch for authorization...
					</Typography>
					<meta httpEquiv="Refresh" content={this.content}/>
				</Box>
			</div>
    );
  }

}

export default RedirectTwitch;
import React, { Component } from "react";
import Table from 'react-bootstrap/Table';
import ContentGeneratorRow from "./ContentGeneratorRow";
import Button from 'react-bootstrap/Button';
import NewContentGeneratorModal from "./NewContentGeneratorModal";
import AddRoundedIcon from '@material-ui/icons/AddRounded';

class ContentGeneratorTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      contentGenerators: {},
    };

    this.modalElement = React.createRef();
  }

  componentDidMount() {
    this.getContentGenerators();
  }

  setContentGenerators = (contentGenerators) => {
    this.setState({ contentGenerators: contentGenerators });
  }

  getContentGenerators = () => {
    const requestOptions = {
      method: 'GET',
      credentials: 'include',
    };

    fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/contentGenerator', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ contentGenerators: data });
      });
  }

  deleteContentGenerator = (contentGeneratorId) => {
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
    };

    fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/contentGenerator/delete?contentGeneratorId=' + contentGeneratorId, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ contentGenerators: data });
      });
  }

  openModal = () =>{
    this.modalElement.current.handleShow();
  }

  render() {
    return (
      <Table striped bordered hover responsive="xl">
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Content</th>
            <th style={{maxWidth: 70}}>Actions <Button style={{ margin: '6px' }} variant="success" size='sm' onClick={this.openModal}><AddRoundedIcon fontSize='small'/></Button></th>
          </tr>
        </thead>
        <tbody>
          {Array.from(this.state.contentGenerators).sort((a,b) => a.id - b.id).map((contentGenerator, index) => (
            <ContentGeneratorRow key={index} contentGenerator={contentGenerator} onDelete={() => this.deleteContentGenerator(contentGenerator.id)} />
          ))}
        </tbody>
        <NewContentGeneratorModal ref={this.modalElement} onClose={this.setContentGenerators}/>
      </Table>
    );
  }

}

export default ContentGeneratorTable;
import React, { Component } from "react";
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import deviceFrame from '../img/staticDeviceFrame.gif';

class DeviceCard extends Component {

  intervalId = 0;

  constructor(props) {
    super(props);

    this.state = {
      displayContent: '',
    };

    // Pre-populate the display information from the last fetched content.
    this.setDisplayFromContent(this.props.device.lastFetchedContent);

  }

  async componentDidMount() {
    this.intervalId = setInterval(async () => {
      const requestOptions = {
        method: 'GET',
        credentials: 'include',
      };

      fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/device/get/content/' + this.props.device.deviceId, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.setDisplayFromContent(data);
        });
    }, 5000);
  }

  setDisplayFromContent = (content) => {
    if (content.statName === 'ViewCount') {
      this.setState({ displayContent: content.username + '\'s Viewer Count: ' + content.statValue })
    }
    else if (content.statName === 'SubCount') {
      this.setState({ displayContent: content.username + '\'s Subscriber Count: ' + content.statValue })
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    return (
      <Accordion defaultActiveKey="1">
        <Card bg='light' style={{ minWidth: '28rem', maxWidth: '28rem', marginTop: '25px' }}>
          <Card.Img variant="top" src={deviceFrame} />
          <Accordion.Toggle as={Card.ImgOverlay} eventKey="0" style={{ padding: '7rem' }}>
            <center>
              <Card.Title style={{ color: 'white' }}>{this.state.displayContent}</Card.Title>
            </center>
          </Accordion.Toggle>
          <Card.Body>
            <Card.Title>
              {this.props.device.displayName}
            </Card.Title>
            <Accordion.Collapse eventKey="0">
              <Card.Text>
                Content Generator: {this.props.device.assignedContentGenerator ? this.props.device.assignedContentGenerator.displayName : "Unassigned"}
              </Card.Text>
            </Accordion.Collapse>
          </Card.Body>
        </Card>
      </Accordion>
    );
  }

}

export default DeviceCard;
import React, {Component} from "react";

class Logout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading : true,
    };

  }

  componentDidMount(){
    this.props.setTitle("Logout");

    const requestOptions = {
      method: 'GET',
      credentials: 'include',
    };

    const endpoint = process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/logout';

    fetch(endpoint, requestOptions).then( response => {
      console.log("Logged Out?", response);
      setTimeout(() => { this.setState({isLoading : false}) }, 1000);
    });
  }

  afterLogout = () => {
    window.location.pathname='/'
  }

  render() {
    return <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '10%',
    }}>
      <h3>Logging out...</h3>
      {!this.state.isLoading && this.afterLogout()}
    </div>
  }

}

export default Logout;
import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

class NewDevice extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isShowing: false,
      devices: {},
      deviceName: '',
      shortCode: {},
    };
  }

  handleShow = () => {
    this.getDevices();
    this.setState({ isShowing: true });
  }

  handleClose = () => {
    this.setState({ isShowing: false });
  }

  handleShortCodeChange = (event) => {
    this.setState({shortCode : event.target.value});
  }

  handleDisplayNameChange = (event) => {
    this.setState({displayName : event.target.value});
  }

  handleLink = () => {
    // GET request using fetch with async/await
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
    };

    this.setState({ isLoading: true })

    fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/device/add?shortCode=' + this.state.shortCode + "&displayName=" + this.state.deviceName, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ devices: data });
        this.props.onClose(data);
      });
    this.handleClose();
  }

  getDevices = () => {
    const requestOptions = {
      method: 'GET',
      credentials: 'include',
    };

    fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/device', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ devices: data });
        this.setState({ deviceName: "New Device " + (data.length+1)});
      });
  }

  render() {
    return (
      <>
        <Modal show={this.state.isShowing} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>New Device</Modal.Title>
          </Modal.Header>
          <Form style={{marginTop: '10px' , marginLeft: '10px' , marginRight: '10px'}}>

            <Form.Group controlId="formShortCode">
              <Form.Label>Code</Form.Label>
              <Form.Control type="text" placeholder="Code" onChange={this.handleShortCodeChange} />
            </Form.Group>

            <Form.Group controlId="controlDisplayName">
              <Form.Label>Device Name</Form.Label>
              <Form.Control type="Username" placeholder={this.state.deviceName} onChange={this.handleDisplayNameChange}/>
            </Form.Group>

          </Form>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleLink}>
              Link
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

}

export default NewDevice;
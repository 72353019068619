import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';

class ContentGeneratorRow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isHidden: false,
    };

  }

  isHidden = (hide) => {
    this.setState({ isHidden: hide });
  }

  render() {
    return (
      <tr>
        <td>{this.props.contentGenerator.displayName}</td>
        <td>{this.props.contentGenerator.type}</td>
        {(this.state.isHidden || !this.props.contentGenerator.content) ? <td>- - - - - - - - - - - - - - - - - - - - - - - - - - - -</td> : <td>User: {this.props.contentGenerator.content.username}, Stat: {this.props.contentGenerator.content.statName}, Value: {this.props.contentGenerator.content.statValue}</td>}
        <td>
          <center>
            <Button style={{ margin: '5px' }} variant="danger" size='sm' onClick={() => this.props.onDelete()}><DeleteForeverRoundedIcon fontSize='small'/></Button>
            {this.state.isHidden ? <Button style={{ margin: '5px' }} variant="primary" size='sm' onClick={() => this.isHidden(false)}><VisibilityRoundedIcon fontSize='small'/></Button> : <Button style={{ margin: '5px' }} variant="primary" size='sm' onClick={() => this.isHidden(true)}><VisibilityOffRoundedIcon fontSize='small'/></Button>}
          </center>
        </td>
      </tr>
    );
  }

}

export default ContentGeneratorRow;
import React, { Component } from "react";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import DeviceRow from "./DeviceRow";
import NewDeviceModal from "./NewDeviceModal"
import AddRoundedIcon from '@material-ui/icons/AddRounded';

class DeviceTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      devices: {},
    };

    this.modalElement = React.createRef();
  }

  componentDidMount() {
    this.getDevices();
  }

  getDevices = () => {
    // GET request using fetch with async/await
    const requestOptions = {
      method: 'GET',
      credentials: 'include',
    };

    fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/device', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ devices: data });
      });
  }

  setDevices = (devices) => {
    this.setState({ devices : devices });
  }

  isHidden = (hide) => {
    this.setState({ isHidden: hide });
  }

  deleteDevice = (deviceId) => {
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
    };

    fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/device/delete?deviceId=' + deviceId, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ devices: data });
      });
  }

  openModal = () =>{
    this.modalElement.current.handleShow();
  }

  setDevices = (devices) => {
    this.setState({ devices: devices });
  }

  render() {
    return (
      <Table striped bordered hover responsive="xl">
        <thead>
          <tr>
            <th>Name</th>
            <th>Content Generator</th>
            <th>Device Id</th>
            <th style={{maxWidth: 70}}>Actions <Button style={{ margin: '6px' }} variant="success" size='sm' onClick={this.openModal}><AddRoundedIcon/></Button></th>
          </tr>
        </thead>
        <tbody>
          {Array.from(this.state.devices).sort((a,b) => a.id - b.id).map((device, index) => (
            <DeviceRow key={index} device={device} onClose={this.setDevices} onDelete={() => this.deleteDevice(device.deviceId)} />
          ))}
        </tbody>
        <NewDeviceModal ref={this.modalElement} onClose={this.setDevices}/>
      </Table>
    );
  }

}

export default DeviceTable;
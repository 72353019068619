import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";

class LoggedOutApp extends Component {

	constructor(props) {
		super(props);

		this.state = {
			currentPage: "Error",
			auth: true,
			anchorEl: false,
			drawerIsOpen: false,
			open: false,
		}
	}

	setTitle(currentPage) {
		this.setState({ currentPage: currentPage });
	}

	handleToggleDrawer = booleanValue => () => {
		this.setState({ drawerIsOpen: booleanValue });
	}

	render() {
		return (
			<div>
				<BrowserRouter>
					<Switch>
						<Route exact path="/login">
							<Login />
						</Route>

						<Route exact path="/register">
							<Register />
						</Route>

						<Route path="/">
							<Redirect to="/login"/>
						</Route>
					</Switch>
				</BrowserRouter>
			</div>
		);
	}
}

export default LoggedOutApp;
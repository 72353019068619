import React, { Component } from "react";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import ApiKeyTable from "./ApiKeyTable";
import DeviceTable from "./DeviceTable";
import ContentGeneratorTable from "./ContentGeneratorTable";

class Manage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      username: '',
      authorities: {},
      apiKeys: {},
      devices: {},
    };

  }

  componentDidMount() {
    this.props.setTitle("Manage");

    // GET request using fetch with async/await
    const requestOptions = {
      method: 'GET',
      credentials: 'include',
    };

    fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/me', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ email: data.email });
        this.setState({ username: data.username });
        this.setState({ authorities: data.authorities });
      });
  }

  render() {
    return (
      <Container fluid style={{maxWidth: '950px'}}>
        <Col>
          {/* TABLE OF API KEYS */}
          <Card bg='light' style={{marginTop: '20px', marginLeft: '5px', marginRight: '5px'}}>
            <Card.Header as="h5">API's</Card.Header>
            <Card.Body>
              <ApiKeyTable />
            </Card.Body>
          </Card>

          {/* TABLE OF CONTENT GENERATORS */}
          <Card bg='light' style={{margin: '5px'}}>
            <Card.Header as="h5">Content Generators</Card.Header>
            <Card.Body>
              <ContentGeneratorTable />
            </Card.Body>
          </Card>

          {/* TABLE OF DEVICES */}
          <Card bg='light' style={{margin: '5px'}}>
            <Card.Header as="h5">Devices</Card.Header>
            <Card.Body>
              <DeviceTable />
            </Card.Body>
          </Card>
        </Col>
      </Container>
    );
  }

}

export default Manage;
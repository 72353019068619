import React, { Component } from "react";
import Table from 'react-bootstrap/Table';
import ApiKeyRow from "./ApiKeyRow";

class ApiKeyTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      apiKeys : {},
    };

  }

  componentDidMount() {
    // GET request using fetch with async/await
    const requestOptions = {
      method: 'GET',
      credentials: 'include',
    };

    fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/apiKey', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ apiKeys : data });
      });
  }

  deleteApiKey = (keyId) => {
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
    };

    fetch(process.env.REACT_APP_STREAMAPPS_BACKEND_URL + '/user/apiKey/delete?keyId=' + keyId, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ apiKeys: data });
      });
  }

  isHidden = (hide) => {
    this.setState({isHidden : hide});
  }

  render() {
    return (
      <Table striped bordered hover responsive="xl">
        <thead>
          <tr>
            <th>Service</th>
            <th>Key</th>
            <th style={{maxWidth: 70}}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.from(this.state.apiKeys).map((apiKey, index) => (
            <ApiKeyRow key={index} apiKey={apiKey} onDelete={() => this.deleteApiKey(apiKey.id)} />
          ))}
        </tbody>
      </Table>
    );
  }

}

export default ApiKeyTable;